interface Steps {
  title: string;
  description: string;
};

export const stepsData: Steps[] = [
  {
    title: 'Согласование',
    description: 'Всегда рисуем макет оформления, отправляем вам коммерческое предложение с учетом доставки и монтажа.',
  },
  {
    title: 'Гос. закупки',
    description: 'Мы участвуем в закупках по 44-ФЗ в качестве поставщика услуг.',
  },
  {
    title: 'Эл. магазин ЛО',
    description: 'Вы можете произвести закупку у нас с помощью Электронного магазина Ленинградкой области.',
  },
  {
    title: 'ЭДО',
    description: 'Мы используем систему электронного документооборота Контур.Диадок.',
  },
];
