import IMG_1 from '../assets/gallery/1.webp';
import IMG_2 from '../assets/gallery/2.webp';
import IMG_3 from '../assets/gallery/3.webp';
import IMG_4 from '../assets/gallery/4.webp';
import IMG_5 from '../assets/gallery/5.webp';
import IMG_6 from '../assets/gallery/6.webp';
import IMG_7 from '../assets/gallery/7.webp';
import IMG_8 from '../assets/gallery/8.webp';
import IMG_9 from '../assets/gallery/9.webp';
import IMG_10 from '../assets/gallery/10.webp';
import IMG_11 from '../assets/gallery/11.webp';
import IMG_12 from '../assets/gallery/12.webp';
import IMG_13 from '../assets/gallery/13.webp';
import IMG_14 from '../assets/gallery/14.webp';
import IMG_15 from '../assets/gallery/15.webp';
import IMG_16 from '../assets/gallery/16.webp';
import IMG_17 from '../assets/gallery/17.webp';
import IMG_18 from '../assets/gallery/18.webp';
import IMG_19 from '../assets/gallery/19.webp';
import IMG_20 from '../assets/gallery/20.webp';
import IMG_21 from '../assets/gallery/21.webp';
import IMG_22 from '../assets/gallery/22.webp';
import IMG_23 from '../assets/gallery/23.webp';
import IMG_24 from '../assets/gallery/24.webp';
import IMG_25 from '../assets/gallery/25.webp';
import IMG_26 from '../assets/gallery/26.webp';
import IMG_27 from '../assets/gallery/27.webp';
import IMG_28 from '../assets/gallery/28.webp';
import IMG_29 from '../assets/gallery/29.webp';
import IMG_30 from '../assets/gallery/30.webp';
import IMG_31 from '../assets/gallery/31.webp';
import IMG_32 from '../assets/gallery/32.webp';
import IMG_33 from '../assets/gallery/33.webp';
import IMG_34 from '../assets/gallery/34.webp';
import IMG_35 from '../assets/gallery/35.webp';
import IMG_36 from '../assets/gallery/36.webp';
import IMG_37 from '../assets/gallery/37.webp';
import IMG_38 from '../assets/gallery/38.webp';
import IMG_39 from '../assets/gallery/39.webp';
import IMG_40 from '../assets/gallery/40.webp';

export const galleryData = [
  IMG_1, IMG_2, IMG_3, IMG_4, IMG_5, IMG_6, IMG_7, IMG_8, IMG_9, IMG_10, IMG_11, IMG_12, IMG_13, IMG_14, IMG_15, IMG_16, IMG_17, IMG_18, IMG_19, IMG_20, IMG_21, IMG_22, IMG_23, IMG_24, IMG_25, IMG_26, IMG_27, IMG_28, IMG_29, IMG_30, IMG_31, IMG_32, IMG_33, IMG_34, IMG_35, IMG_36, IMG_37, IMG_38, IMG_39, IMG_40
];
